'use client';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Image from 'next/image';

import Loading from '@/app/loading';
import { AuthCredentials } from '@/config/firebase/auth';
import { useCheckEmailWhitelist } from '@/hooks/register/use-check-email-whitelist';
import { auth, googleProvider } from '@/lib/firebase/client-config';
import { handleLogin } from '@/services/firebase/auth';
import { schemaLogin, SchemaLoginProps } from '@/utils/schema/login';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@mui/material';
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { setCookie } from 'nookies';
import { useTheme } from 'styled-components';

import {
  Button,
  LinkButton,
  Checkbox,
  Divider,
  Input,
  ModalInfo,
} from '@/components';
import Tips from '@/components/tips';

import * as S from './styles';

export default function Login() {
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.laptop);

  const [googleIsLoading, setGoogleIsLoading] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [customErrorMessage] = useState('');
  const [signInWith, setSignInWith] = useState<'Email' | ''>('');

  const { formState, register, handleSubmit, setValue, setError } = useForm({
    resolver: yupResolver(schemaLogin),
  });

  const {
    mutateAsync: checkEmail,
    isLoading: isLoading,
    isError,
    isSuccess,
    error,
  } = useCheckEmailWhitelist();

  async function signIn(loginProvider: GoogleAuthProvider | OAuthProvider) {
    signInWithPopup(auth, loginProvider)
      .then((userCred) => {
        handleLogin(userCred)
          .then(async (response) => {
            if (response.status === 500) {
              await checkEmail(userCred.user.email ?? '');
              if (!error) {
                setCookie(null, 'userId', userCred.user.uid, {
                  path: '/',
                });
                setCookie(null, 'token', userCred.user.refreshToken, {
                  path: '/',
                });
                localStorage.setItem(
                  'firebaseUser',
                  JSON.stringify(userCred.user)
                );
                window.location.href = '/register';
              }
            } else {
              window.location.href = '/organizations';
            }
          })
          .catch((error) => {
            console.error('Erro ao fazer login:', error.message);
            setGoogleIsLoading(false);
          });
      })
      .catch(() => {
        setGoogleIsLoading(false);
      });
  }

  async function handleLoginPass(credentials?: AuthCredentials) {
    setSignInWith('Email');
    signInWithEmailAndPassword(
      auth,
      credentials?.email ?? '',
      credentials?.password ?? ''
    )
      .then((userCred) => {
        handleLogin(userCred)
          .then(() => {
            window.location.href = '/organizations';
          })
          .catch(() => {
            setSignInWith('');
          });
      })
      .catch((error) => {
        setSignInWith('');
        console.log(error.code);
        if (error?.code === 'auth/user-not-found') {
          return setError('email', {
            type: 'manual',
            message: 'Email ou senha inválido',
          });
        }

        if (error?.code === 'auth/invalid-credential') {
          return setError('email', {
            type: 'manual',
            message: 'Email ou senha inválido',
          });
        }

        if (error?.code === 'auth/wrong-password') {
          return setError('password', {
            type: 'manual',
            message: 'Senha inválida',
          });
        }
      });
  }

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return <Loading />;
  }

  return (
    <S.Main data-testid="layoutLogin">
      <section className="sectionForm">
        <S.WrapperImage>
          <Image
            src="/assets/images/logo-assiny-beta-black.svg"
            alt="Logo assiny"
            width={600}
            height={300}
            priority
            style={{
              width: '210px',
              height: '30px',
              objectFit: 'cover',
              objectPosition: 'left',
            }}
          />
        </S.WrapperImage>

        <S.Title>Bem-vindo de volta!</S.Title>
        <S.Subtitle>Insira seus dados de login</S.Subtitle>

        <Button
          color="white"
          className="buttonGoogle"
          type="button"
          icon={
            <Image
              src="/assets/images/logo-google.png"
              alt="Logo"
              width={23}
              height={23}
            />
          }
          onClick={() => {
            signIn(googleProvider);
            setGoogleIsLoading(true);
          }}
          size="md"
          isLoading={googleIsLoading}
          disabled={googleIsLoading}>
          {googleIsLoading ? 'Carregando...' : 'Continue com Google'}
        </Button>

        <Divider className="divider" variant="primary" />
        <S.Form
          method="POST"
          onSubmit={handleSubmit((data: SchemaLoginProps) =>
            handleLoginPass(data)
          )}>
          <Input
            data-test="email"
            label="E-mail"
            placeholder="Digite seu e-mail"
            classNameWrapper="email"
            name="email"
            errorMessage={formState.errors.email?.message}
            error={!!formState.errors.email}
            register={register}
          />

          <Input
            password
            classNameWrapper="password"
            data-test="password"
            label="Senha"
            placeholder="Digite sua senha"
            name="password"
            errorMessage={formState.errors.password?.message}
            error={!!formState.errors.password}
            register={register}
          />

          {customErrorMessage && (
            <Tips type="error" message={customErrorMessage} />
          )}

          <div className="wrapperForgotPassword">
            <Checkbox
              size="sm"
              label="Lembrar-me"
              onChange={(value) => {
                setValue('rememberMe', Boolean(value));
              }}
            />

            <LinkButton size="sm" href="/forgot">
              Esqueci a senha
            </LinkButton>
          </div>

          <Button
            data-test="button-login"
            color="primary"
            className="buttonLogin"
            type="submit"
            size="md"
            isLoading={signInWith === 'Email'}
            disable={signInWith === 'Email'}>
            {signInWith === 'Email' ? 'Autenticando...' : 'Iniciar sessão'}
          </Button>
        </S.Form>
        <div className="wrapperNotAccount">
          <S.Description>Não tem uma conta</S.Description>
          <LinkButton size="sm" href="/register">
            Cadastre-se
          </LinkButton>
        </div>
      </section>

      {isLaptop && (
        <section className="sectionImage">
          <Image
            priority
            fill
            src="/assets/images/banner-login-new.png"
            alt="Gerencie de forma ágil e prática, sem complicação!"
            sizes="(max-width: 768px) 100%, (max-width: 1200px) 100%"
          />
        </section>
      )}
      {error && (
        <ModalInfo
          defaultOpen
          uniqueButton
          typeUniqueButton="success"
          title="Necessário liberar a conta"
          description={' '}
          status="error"
          TriggerButton={null}
          primaryButton={'Fechar'}
        />
      )}
    </S.Main>
  );
}
